import React, { useEffect, useState } from "react"
import ReactSVG from "react-svg"
import CarsLogo from "../../assets/images/cars.svg"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { ANCHOR_BLANK, ANCHOR_FEATURES, KEY, SECTION, STEP } from "../../constants/sfo"
import { FECTH_SFO_TRACK, IS_SFO_SKIPPED } from "../../actions/sagas/sfoSaga"
import SplitClient, { ON, SFO_FLAG } from "../../components/config/SplitClient"
import { getSubdomain } from "../../components/shared/StylesheetInjector"

const SfoTracking = ({ currentUser, dispatch }) => {
  const { t } = useTranslation("sfoTrack")

  const [sfoTrack, setSfoTrack] = useState({
    section: null,
    step: null
  })
  const [skipOnboarding, setSkipOnboarding] = useState(false)
  const [isSfoFlag, setIsSfoFlag] = useState(false)

  useEffect(() => {
    handleSfoFeatureFlag()
    dispatch({
      type: FECTH_SFO_TRACK,
      callback: getSfoTrackingSteps
    })

    if (currentUser.fleet_id) {
      dispatch({
        type: IS_SFO_SKIPPED,
        payload: {
          fleetId: currentUser.fleet_id
        },
        callback: isOnBoardingSkipped
      })
    }
  }, [])

  const handleSfoFeatureFlag = async () => {
    const result = await SplitClient.getTreatment(SFO_FLAG, currentUser, {
      sub_domain: getSubdomain(),
      app_name: getSubdomain()
    })
    setIsSfoFlag(result[SFO_FLAG] === ON)
  }

  const getSfoTrackingSteps = (_, data) => {
    setSfoTrack({
      section: data.step,
      step: data.sub_step
    })
  }
  const isOnBoardingSkipped = (status, data) => {
    setSkipOnboarding(data.skip_onboarding)
  }

  const redirectToSFO = () => {
    const url = new URL(process.env.REACT_APP_SFO_URL5)

    // Create the URL search parameters
    const params = new URLSearchParams({
      [KEY]: currentUser.authentication_token,
      [SECTION]: sfoTrack.section,
      [STEP]: sfoTrack.step
    })

    // Append the search parameters to the URL
    url.search = params.toString()

    // Open the new window with the constructed URL
    const newWindow = window.open(url.toString(), ANCHOR_BLANK, ANCHOR_FEATURES)

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const shouldSfoRender = isSfoFlag && !skipOnboarding && sfoTrack.section && sfoTrack.section !== 3

  return shouldSfoRender ? (
    <div className="sfo__tracking" onClick={redirectToSFO}>
      <ReactSVG src={CarsLogo} />
      <div>
        <p className="sfo__tracking-label">{t("sfoLabel")}</p>
      </div>
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(SfoTracking)
